import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.scss';

const Header = () => {
  return (
    <header className="header">
      <div className="header-content">
        <h1>Mythic Tales from Brigadoon</h1>
        <nav>
          <Link to="/">Home</Link>
          <Link to="/stories">Stories</Link>
          <Link to="/art">Art Gallery</Link>
          <Link to="/about-us">About Us</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/merchandise">Merchandise</Link>
          <Link to="/forum">Forum</Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
