import React from 'react';
import { useParams } from 'react-router-dom';
import '../styles/ArtDetail.scss';

// Assuming artworks array is imported or fetched
import witchImg from '../assets/witch.png';

const artworks = [
  { id: 1, title: 'Witch of ben-y-gloe', img: witchImg, description: 'Beautiful Scottish witch', artist: 'AI' },
  // Add more artworks here
];

const ArtDetail = () => {
  const { id } = useParams();
  const art = artworks.find(a => a.id === parseInt(id));

  if (!art) {
    return <div>Artwork not found</div>;
  }

  return (
    <div className="art-detail">
      <h2>{art.title}</h2>
      <img src={art.img} alt={art.title} className="full-screen-image" />
      <div className="art-info">
        <p>{art.description}</p>
        <p><strong>Artist:</strong> {art.artist}</p>
      </div>
    </div>
  );
};

export default ArtDetail;
