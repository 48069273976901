import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/StoryList.scss';

const stories = [
  { id: 1, title: 'Kelpies', theme: 'Kelpies' },
  { id: 2, title: 'Selkies', theme: 'Selkies' },
  // Add more stories here
];

const StoryList = () => {
  return (
    <div className="story-list">
      {stories.map(story => (
        <Link to={`/stories/${story.id}`} key={story.id}>
          <div className="story-item">
            <h3>{story.title}</h3>
            <p>{story.theme}</p>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default StoryList;
