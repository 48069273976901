import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Blog.scss';

const blogPosts = [
  { id: 1, title: 'The Magic of Scottish Folklore' },
  { id: 2, title: 'Behind the Scenes: Creating Fantasy Art' },
  // Add more blog posts here
];

const Blog = () => {
  return (
    <div className="blog">
      <h2>Blog</h2>
      <ul>
        {blogPosts.map(post => (
          <li key={post.id}>
            <Link to={`/blog/${post.id}`}>{post.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Blog;
