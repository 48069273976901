import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/main.scss';

// Import pages
import Home from './pages/Home';
import StoryList from './pages/StoryList';
import StoryDetail from './pages/StoryDetail';
import ArtGallery from './pages/ArtGallery';
import ArtDetail from './pages/ArtDetail';
import AboutUs from './pages/AboutUs';
import Blog from './pages/Blog';
import BlogDetail from './pages/BlogDetail';
import Merchandise from './pages/Merchandise';
import Forum from './pages/Forum';

// Import components
import Header from './components/Header';
import Footer from './components/Footer';

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/stories" element={<StoryList />} />
        <Route path="/stories/:id" element={<StoryDetail />} />
        <Route path="/art" element={<ArtGallery />} />
        <Route path="/art/:id" element={<ArtDetail />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path="/merchandise" element={<Merchandise />} />
        <Route path="/forum" element={<Forum />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
