import React from 'react';
import '../styles/AboutUs.scss';

const AboutUs = () => {
  return (
    <div className="about-us">
      <div className="panel">
        <section className="about-intro">
          <h2>About Mythic Tales from Brigadoon</h2>
          <p>At Mythic Tales from Brigadoon, our mission is to bring the enchanting stories of Scottish folklore to life through captivating narratives and stunning fantasy art. Our vision is to create a platform where the rich cultural heritage of Scotland can be preserved and shared with audiences around the world.</p>
        </section>
      </div>
      <div className="panel">
        <section className="creators">
          <h3>Meet the Creators</h3>
          <div className="creator">
            <h4>Jamie Fraser</h4>
            <p>Jamie collaborates on the stories and handles the editing, ensuring each tale is presented in the most captivating way possible.</p>
          </div>
          <div className="creator">
            <h4>Alexander Fraser</h4>
            <p>Alexander works with Jamie to create and develop the stories, bringing rich detail and depth to the ancient myths.</p>
          </div>
          <div className="creator">
            <h4>AI Art</h4>
            <p>We currently use AI to generate our artwork. As our channel grows, we plan to hire artists to create custom illustrations and redo our old stories with new, unique artwork.</p>
          </div>
        </section>
      </div>
      <div className="panel">
        <section className="mission-vision">
          <h3>Our Mission</h3>
          <p>Our mission is to preserve and share the rich cultural heritage of Scotland through captivating stories and stunning art.</p>
          <h3>Our Vision</h3>
          <p>Our vision is to be the leading platform for Scottish folklore, inspiring and educating audiences worldwide about the magic of Scotland’s mythical past.</p>
        </section>
      </div>
      <div className="panel">
        <section className="contact">
          <h3>Contact Us</h3>
          <p>If you have any questions, comments, or inquiries, please feel free to reach out to us through our contact form or email us directly at <a href="mailto:contact@mythictalesfrombrigadoon.co.uk">contact@mythictalesfrombrigadoon.co.uk</a>.</p>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
