import React from 'react';
import { useParams } from 'react-router-dom';
import '../styles/BlogDetail.scss';

const blogPosts = [
  { id: 1, title: 'The Magic of Scottish Folklore', content: 'Detailed content about Scottish folklore...' },
  { id: 2, title: 'Behind the Scenes: Creating Fantasy Art', content: 'Insights into creating fantasy art...' },
  // Add more blog posts here
];

const BlogDetail = () => {
  const { id } = useParams();
  const post = blogPosts.find(item => item.id.toString() === id);

  if (!post) {
    return <p>Blog post not found</p>;
  }

  return (
    <div className="blog-detail">
      <h2>{post.title}</h2>
      <p>{post.content}</p>
    </div>
  );
};

export default BlogDetail;
