import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import '../styles/Home.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import selkiesImg from '../assets/selkies.png';
import waterSpiritImg from '../assets/kelpies.png';
import witchImg from '../assets/witch.png';
import blueMenImg from '../assets/bluemen.png';
import wulverImg from '../assets/wulver.png';
import faerieImg from '../assets/faerie.png';

const stories = [
  { id: 1, title: 'The Mysterious Selkies of Scotland', img: selkiesImg, description: 'Discover the enchanting selkies of Scottish lore.' },
  { id: 2, title: 'The Shapeshifting Water Spirit of Scotland', img: waterSpiritImg, description: 'Explore the myth of the shapeshifting water spirit.' },
  { id: 3, title: 'The Witch of Ben-y-Gloe', img: witchImg, description: 'Uncover the legend of the Witch of Ben-y-Gloe.' },
  { id: 4, title: 'The Faerie Folk: Tales of the Hidden People', img: faerieImg, description: 'Meet the magical faerie folk of Scotland.' },
  { id: 5, title: 'The Blue Men of the Minch', img: blueMenImg, description: 'Learn about the mysterious Blue Men of the Minch.' },
  { id: 6, title: 'The Wulver of Shetland', img: wulverImg, description: 'Find out about the Wulver of Shetland, a werewolf-like creature.' },
];

const videos = [
  { id: 1, title: 'The Blue Men of the Minch', url: 'https://www.youtube.com/embed/izdPurtLJHg' },
  { id: 2, title: 'The Wulver of Shetland', url: 'https://www.youtube.com/embed/kUl0DPyCRtk' },
];

const popularStories = [
  { id: 3, title: 'The Witch of Ben-y-Gloe', img: witchImg, link: '/stories/3' },
  { id: 4, title: 'The Faerie Folk: Tales of the Hidden People', img: faerieImg, link: '/stories/4' },
];

const upcomingReleases = [
  { id: 1, title: 'Tam Lin' },
];

const Home = () => {
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <div className="home">
      <section className="intro">
        <h2>Welcome to Mythic Tales from Brigadoon</h2>
        <p>Discover captivating stories from Scottish folklore, accompanied by stunning fantasy art.</p>
      </section>
      <section className="featured">
        <h2>Featured Stories</h2>
        <Slider {...carouselSettings}>
          {stories.map(story => (
            <div key={story.id} className="carousel-item">
              <Link to={`/stories/${story.id}`}>
                <img src={story.img} alt={story.title} className="carousel-image" />
                <h3>{story.title}</h3>
                <p>{story.description}</p>
              </Link>
            </div>
          ))}
        </Slider>
      </section>
      <section className="latest-videos">
        <h2>Latest Videos</h2>
        <div className="videos">
          {videos.map(video => (
            <div key={video.id} className="video">
              <iframe
                width="560"
                height="315"
                src={video.url}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <h3>{video.title}</h3>
            </div>
          ))}
        </div>
      </section>
      <section className="popular-stories">
        <h2>Popular Stories</h2>
        <div className="stories">
          {popularStories.map(story => (
            <div key={story.id} className="story">
              <Link to={story.link}>
                <img src={story.img} alt={story.title} className="popular-story-image" />
                <h3>{story.title}</h3>
              </Link>
            </div>
          ))}
        </div>
      </section>
      <section className="upcoming-releases">
        <h2>Upcoming Releases</h2>
        <ul>
          {upcomingReleases.map(release => (
            <li key={release.id}>
              <h3>{release.title}</h3>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default Home;
