import React from 'react';
import { useParams } from 'react-router-dom';
import '../styles/StoryDetail.scss';

const stories = [
  {
    id: 1,
    title: 'The Legend of the Kelpies',
    theme: 'Kelpies',
    narrative: 'Long ago, the Kelpies roamed the waters of Scotland...',
    img: '/assets/kelpies.jpg',
    videoUrl: 'https://www.youtube.com/embed/exampleVideo'
  },
  // Add more stories here
];

const StoryDetail = () => {
  const { id } = useParams();
  const story = stories.find(item => item.id.toString() === id);

  if (!story) {
    return <p>Story not found</p>;
  }

  return (
    <div className="story-detail">
      <h2>{story.title}</h2>
      <img src={story.img} alt={story.title} className="story-image" />
      <p>{story.narrative}</p>
      <div className="video-wrapper">
        <iframe
          width="560"
          height="315"
          src={story.videoUrl}
          title={story.title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="comments-section">
        <h3>Comments</h3>
        {/* Implement the comments section */}
      </div>
    </div>
  );
};

export default StoryDetail;
