import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ArtGallery.scss';

import witchImg from '../assets/witch.png';

const artworks = [
  { id: 1, title: 'Witch of ben-y-gloe', img: witchImg, description: 'Beautiful Scottish witch', artist: 'AI' },
  // Add more artworks here
];

const ArtGallery = () => {
  return (
    <div className="art-gallery">
      <h2>Art Gallery</h2>
      <p>Our full Art Gallery is coming soon! Stay tuned for more stunning fantasy art inspired by Scottish mythology.</p>
      <div className="artworks">
        {artworks.map(art => (
          <Link to={`/art/${art.id}`} key={art.id}>
            <div className="art-item">
              <img src={art.img} alt={art.title} />
              <div className="art-info">
                <h3>{art.title}</h3>
                <p>{art.description}</p>
                <p><strong>Artist:</strong> {art.artist}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ArtGallery;
