import React from 'react';
import '../styles/Merchandise.scss';

const merchandiseItems = [
  { id: 1, title: 'Fantasy Art Print', description: 'High-quality print of fantasy art.', price: '£20' },
  { id: 2, title: 'Brigadoon T-Shirt', description: 'Comfortable t-shirt with Brigadoon logo.', price: '£25' },
  // Add more merchandise items here
];

const Merchandise = () => {
  return (
    <div className="merchandise">
      <h2>Merchandise</h2>
      <ul>
        {merchandiseItems.map(item => (
          <li key={item.id}>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            <p><strong>Price:</strong> {item.price}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Merchandise;
