import React from 'react';
import '../styles/Forum.scss';

const Forum = () => {
  return (
    <div className="forum">
      <h2>Community Forum</h2>
      <p>Discuss Scottish folklore, share stories, and interact with other users.</p>
      {/* Implement forum functionality here */}
    </div>
  );
};

export default Forum;
